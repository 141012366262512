import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from 'containers/ApplicationFormPage/messages';
import {
  regexLongDashCharacters,
  regexPhoneNumber,
  regexNumbers,
} from 'helpers/lang';

//validators
import isEmail from 'validator/lib/isEmail';
import { isUS } from 'helpers/lang';
import { isEmpty } from 'lodash/lang';
import { forEach, map } from 'lodash/collection';
import { isFieldEmpty } from 'utils/form';
import { getStartDate } from 'containers/ApplicationFormPage/helpers';
import { productCodeCheck, isDisabledField } from 'helpers/constants';

const legalAge = getStartDate()
  .toDate()
  .setFullYear(
    getStartDate()
      .toDate()
      .getFullYear() - 18,
  );

const validate = (values, props) => {
  const errors = {};
  const disabledFields = props?.disabledFields;
  const additionalInforceFields = props?.isInforce ? ['isSmoke'] : [];

  if (!values.isEmpty()) {
    const parties = values.get('lifeInsured').toJS();
    const productCode = values.get('productCode');
    var partiesArrayErrors = [];
    const requiredTextFields = [
      'dateOfBirth',
      'email',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'city',
      'primaryNationality',
      'primaryPhoneNumber',
      ...additionalInforceFields,
    ];

    const nonRequiredTextFields = [
      'postOfficeBoxLine',
      'postOfficeBoxCity',
      'secondaryNationality',
      'preferredName',
      'secondaryCountryDialingCode',
      'secondaryPhoneNumber',
      'dateOfBirth',
      'primaryCountryDialingCode',
    ];

    const requiredSelectFields = [
      'country',
      'relationshipToPolicyOwner',
      'countryOfBirth',
      'primaryCountryDialingCode',
    ];
    const _getAge = (dob) => {
      let month_diff = Date.now() - new Date(dob).getTime();
      let age_dt = new Date(month_diff);
      let year = age_dt.getUTCFullYear();
      let age = Math.abs(year - 1970);
      return age
    }

    forEach(parties, (party, index) => {
      const isLifeInsured =
        party?.partyapplicationrelationships?.isPrimaryLifeInsured;
      const partyErrors = {};
      const relationshipToLifeInsured = party?.partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured;
      forEach([...requiredTextFields, ...nonRequiredTextFields], field => {
        if (
          !isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, field)
        ) {
          if (isFieldEmpty(party[field])) {
            if (!nonRequiredTextFields.includes(field)) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          } else if ('email' === field) {
            if (!isEmail(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.emailInvalid} />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (party[field]?.length > 70) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 70 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          } else if (
            'addressLine1' === field ||
            'addressLine2' === field ||
            'addressLine3' === field ||
            'city' === field ||
            'postOfficeBoxCity' === field
          ) {
            if (party[field].length > 35) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }

            if (regexLongDashCharacters.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.unsupportedChars} />
              );

              partiesArrayErrors[index] = partyErrors;
            }
          } else if ('dateOfBirth' === field) {
            const today = new Date().setFullYear(new Date().getFullYear());
            const date = Date.parse(party[field]);
            const age = _getAge(party[field])
            if (date > today) {
              partyErrors[field] = (
                <FormattedMessage {...messages.futureDates} />
              );
              partiesArrayErrors[index] = partyErrors;
            } /*else if (age < 1 && relationshipToLifeInsured === 'CHILD') {
              partyErrors[field] = <FormattedMessage {...messages.minAge} />;
            }*/
            else if (age > 30 && relationshipToLifeInsured === 'CHILD') {
              partyErrors[field] = <FormattedMessage {...messages.maxAge} />;
            }
            else if (legalAge < date && relationshipToLifeInsured !== 'CHILD') {
              partyErrors[field] = <FormattedMessage {...messages.legalAge} />;
            }
          } else if ('postOfficeBoxLine' === field) {
            if (party[field].length > 15) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 15 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }

            // else if (regexSpecialCharacters.test(party[field])) {
            //   partyErrors[field] = (
            //     <FormattedMessage
            //       {...globalMessages.specialCharactersNotAllowed}
            //     />
            //   );
            //   partiesArrayErrors[index] = partyErrors;
            // }
          } else if ('preferredName' === field) {
            if (!regexNumbers.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }

          if (
            field === 'primaryCountryDialingCode' &&
            isEmpty(party['primaryPhoneNumber']) &&
            !isEmpty(party[field])
          ) {
            if (index !== 1) {
              partyErrors['primaryPhoneNumber'] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            }
          }

          // PRIMARY NUMBER
          if (
            field === 'primaryPhoneNumber' &&
            !isEmpty(party['primaryPhoneNumber'])
          ) {
            if (party[field].length > 25) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 25 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (!regexPhoneNumber.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.invalidPhoneNumber} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }

          // SECONDARY NUMBER
          if (
            field === 'secondaryCountryDialingCode' &&
            isEmpty(party['secondaryPhoneNumber']) &&
            !isEmpty(party[field])
          ) {
            partyErrors['secondaryPhoneNumber'] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
            partiesArrayErrors[index] = partyErrors;
          } else if (!isEmpty(party['secondaryPhoneNumber'])) {
            if (party['secondaryPhoneNumber'].length > 25) {
              partyErrors['secondaryPhoneNumber'] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 25 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (!regexPhoneNumber.test(party['secondaryPhoneNumber'])) {
              partyErrors['secondaryPhoneNumber'] = (
                <FormattedMessage {...globalMessages.invalidPhoneNumber} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }

          //IS US
          if (productCodeCheck(3, productCode)) {
            if (
              (field === 'primaryNationality' && isUS(party[field])) ||
              (field === 'primaryCountryDialingCode' && isUS(party[field])) ||
              (field === 'secondaryNationality' && isUS(party[field]))
            ) {
              partyErrors[field] = (
                <FormattedMessage {...messages.USCitizencannotProceed} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }
        }
      });
      forEach(requiredSelectFields, field => {
        if (
          !isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, field)
        ) {
          if (isFieldEmpty(party[field])) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
            partiesArrayErrors[index] = partyErrors;
          }
        }
      });
      // if (
      //   !isEmpty(party['postOfficeBoxCity']) &&
      //   isEmpty(party['postOfficeBoxLine'])
      // ) {
      //   if (
      //     !isDisabledField(
      //       disabledFields,
      //       `li${isLifeInsured ? 1 : 2}`,
      //       'postOfficeBoxLine',
      //     )
      //   ) {
      //     partyErrors['postOfficeBoxLine'] = (
      //       <FormattedMessage {...globalMessages.pleaseEnterValue} />
      //     );
      //     partiesArrayErrors[index] = partyErrors;
      //   }
      // }
    });

    if (partiesArrayErrors.length) {
      errors.lifeInsured = partiesArrayErrors;
    }
  }

  return errors;
};

export default validate;
